"use strict";
exports.__esModule = true;
exports.createAppsMenuAndButton = exports.createAppsMenu = void 0;
function createAppsMenu(qs, ops) {
    var targetElement;
    if (typeof qs === 'string') {
        targetElement = document.querySelector(qs);
    }
    else {
        targetElement = qs;
    }
    var iframe = document.createElement('iframe');
    var placement = (ops && ops.placement) || 'right';
    iframe.setAttribute('src', 'https://apps.cnr.it/#/applist');
    iframe.setAttribute('data-cnr-sso-menu-type', 'apps');
    var bcr = targetElement.getBoundingClientRect();
    iframe.style.borderRadius = '4px';
    iframe.style.position = 'absolute';
    iframe.style.top = "" + (bcr.height + 'px');
    if (placement === 'right') {
        iframe.style.right = '0';
    }
    else {
        iframe.style.left = '0';
    }
    iframe.style.width = 'calc(3 * 96px + 16px + 16px)';
    iframe.style.height = '438px';
    iframe.style.border = 'none';
    iframe.style.display = 'none';
    if (ops && ops.style) {
        iframe.setAttribute('style', ops.style);
    }
    targetElement.style.position = 'relative';
    targetElement.onclick = function (e) {
        e.stopPropagation();
        document.querySelectorAll('[data-cnr-sso-menu-type]').forEach(function (item) { return item.style.display = 'none'; });
        iframe.style.display = iframe.style.display === 'none' ? 'block' : 'none';
        return false;
    };
    document.querySelector('body').addEventListener('click', function (ev) { return iframe.style.display = 'none'; });
    targetElement.appendChild(iframe);
}
exports.createAppsMenu = createAppsMenu;
function createAppsMenuAndButton(qs, ops) {
    var targetElement;
    if (typeof qs === 'string') {
        targetElement = document.querySelector(qs);
    }
    else {
        targetElement = qs;
    }
    var b = document.createElement('button');
    var bSize = ops.buttonSize || '40px';
    var iSize = ops.iconSize || '16px';
    b.style.border = 'none';
    b.style.borderRadius = ops.round === false ? '0' : '100%';
    b.style.padding = '0';
    b.style.background = 'transparent';
    b.style.display = 'block';
    b.style.color = 'inherit';
    b.style.height = bSize;
    b.style.width = bSize;
    b.style.display = 'flex';
    b.style.justifyContent = 'center';
    b.style.alignItems = 'center';
    b.setAttribute('type', 'button');
    b.innerHTML = "<svg aria-hidden=\"true\" data-prefix=\"fae\" role=\"img\" xmlns=\"http://www.w3.org/2000/svg\" viewBox=\"0 0 16 16\" style=\"height: " + iSize + ";width: " + iSize + ";display: block;\"><path fill=\"currentColor\" d=\"M2 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm6 12c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm-6 0c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0-6c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6 0c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm4-8c0 1.1.9 2 2 2s2-.9 2-2-.9-2-2-2-2 .9-2 2ZM8 4c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2Zm6 6c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0 6c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z\"></path></svg>";
    targetElement.appendChild(b);
    createAppsMenu(b, ops || {});
}
exports.createAppsMenuAndButton = createAppsMenuAndButton;
